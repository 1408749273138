import { useState, useEffect } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { PageHeader, Table, message, Input, AutoComplete, DatePicker, Space, Button } from "antd";
import { Link, useSearchParams } from "react-router-dom";
import moment from "moment";

import { PageContainer } from "../components/atoms/PageContainer";
import { PageContentContainer } from "../components/atoms/PageContentContainer";

import { StatusTag } from "../components/tags/StatusTag";
import { formatMoney } from "../utils/formatMoney";
import axios from "axios";
import { ProductsBaseQuery } from "@/graphql/products.graphql";
import { ToolbarWrapper } from "../components/atoms/ToolbarWrapper";
import { DownloadOutlined } from "@ant-design/icons";

const columns = [
  {
    title: "Item Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Comments",
    dataIndex: "notes",
    key: "notes",
  },
  {
    title: "Unit",
    dataIndex: ["productVariant", "name"],
    key: "packagingUnit",
  },
  {
    title: "Unit Price",
    dataIndex: "unitPrice",
    key: "unitPrice",
    render: (text) => {
      return formatMoney(text);
    },
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
  },
  {
    title: "Order",
    dataIndex: ["order", "name"],
    key: "order",
    render: (order, record) => {
      return (
        <Space>
          {"#" + order}
          {<StatusTag status={record.order.status} />}
          {<StatusTag status={record.order.fulfillmentStatus} />}
        </Space>
      );
    },
  },
  {
    title: "Picking Status",
    dataIndex: "pickingStatus",
    key: "pickingStatus",
    render: (text) => <StatusTag status={text} />,
  },
  {
    title: "Customer",
    dataIndex: ["order", "customer", "name"],
    key: "customer",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    render: (_, record) => <Link to={`/orders/${record.order.id}`}>Go to order</Link>,
  },
];
const pageSize = 50;

const fetchLineItems = async ({ name, gte, lte, currentPage }) => {
  const params = {
    name,
    ...(gte && { gte }),
    ...(lte && { lte }),
    currentPage,
  };
  const response = await axios(`${process.env.REACT_APP_REST_API_ENDPOINT}/line-items`, {
    params,
  });

  if (response.status >= 200) {
    return response.data;
  }
};

export const LineItems = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [lineItems, setLineItems] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [gte, setGte] = useState(moment().startOf("day").toISOString());
  const [lte, setLte] = useState(moment().endOf("day").toISOString());
  const [name, setName] = useState(null);
  const [loading, setLoading] = useState(false);

  const { data } = useQuery(ProductsBaseQuery, { fetchPolicy: "cache-first" });
  const [products, setProducts] = useState([]);
  useEffect(() => {
    if (data?.products) {
      setProducts(data.products.map((product) => ({ label: product.name, value: product.name })));
    }
  }, [data]);

  useEffect(() => {
    const name = searchParams.get("name");
    if (name) setName(name);
    const gte = searchParams.get("gte");
    if (gte) setGte(gte);
    const lte = searchParams.get("lte");
    if (lte) setLte(lte);
    const page = Number(searchParams.get("currentPage"));
    if (page) setCurrentPage(page);

    const fetchLineItemsData = async () => {
      const res = await fetchLineItems({ name, gte, lte, currentPage });
      if (res) {
        setLineItems(res.lineItems);
        setTotalCount(res.total);
      }
    };
    fetchLineItemsData();
  }, [searchParams]);

  const handleSearch = (value) => {
    if (value) {
      setName(value);
      setCurrentPage(1);
      setSearchParams({ name: value, currentPage: 1, gte, lte }, { replace: true });
    }
  };

  const handleRangeChange = (range) => {
    if (!range) {
      setGte(null);
      setLte(null);
      return;
    }

    const [start, end] = range;

    if (!start) {
      setGte(null);
      setLte(end.endOf("day").toISOString());
      setCurrentPage(1);
      setSearchParams(
        { name, gte, lte: end.endOf("day").toISOString(), currentPage: 1 },
        { replace: true }
      );
      return;
    }
    if (!end) {
      setGte(start.startOf("day").toISOString());
      setLte(null);
      setCurrentPage(1);
      setSearchParams(
        { name, gte: start.startOf("day").toISOString(), lte, currentPage: 1 },
        { replace: true }
      );
      return;
    }

    setGte(start.startOf("day").toISOString());
    setLte(end.endOf("day").toISOString());
    setCurrentPage(1);
    setSearchParams(
      {
        name,
        gte: start.startOf("day").toISOString(),
        lte: end.endOf("day").toISOString(),
        currentPage: 1,
      },
      { replace: true }
    );
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSearchParams({ name, currentPage: page, gte, lte }, { replace: true });
  };

  const handleDownload = async () => {
    try {
      const response = await axios(`${process.env.REACT_APP_REST_API_ENDPOINT}/line-items/excel`, {
        params: { name, gte, lte },
        responseType: "blob",
      });

      if (response.status >= 200) {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = `line-items-${name}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        message.success("Downloaded successfully");
      } else {
        message.error("Failed to download");
      }
    } catch (error) {
      message.error("Failed to download");
    }
  };

  return (
    <PageContainer>
      <PageHeader
        ghost={true}
        title="Search Items"
        subTitle={`Showing ${(currentPage - 1) * pageSize + 1} - ${
          (currentPage - 1) * pageSize + lineItems.length
        } of ${totalCount}`}
      />
      <PageContentContainer>
        <ToolbarWrapper>
          <Space>
            <AutoComplete
              options={products}
              onSelect={(_, option) => handleSearch(option.label)}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
              style={{ width: 320 }}
              dropdownMatchSelectWidth={420}
            >
              <Input.Search
                placeholder="Search product name"
                enterButton
                onSearch={handleSearch}
                allowClear
              />
            </AutoComplete>
            <DatePicker.RangePicker
              defaultValue={[moment().startOf("day"), moment().endOf("day")]}
              allowEmpty={[false, true]}
              onChange={handleRangeChange}
            />
          </Space>
          <Space>
            <Button icon={<DownloadOutlined />} onClick={handleDownload} disabled={!name}>
              Download {totalCount} items as Excel
            </Button>
          </Space>
        </ToolbarWrapper>

        <Table
          dataSource={lineItems}
          columns={columns}
          rowKey="id"
          size="small"
          loading={loading}
          pagination={{
            current: currentPage,
            total: totalCount,
            pageSize: pageSize,
            showSizeChanger: false,
            onChange: handlePageChange,
          }}
        />
      </PageContentContainer>
    </PageContainer>
  );
};
